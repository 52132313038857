body {
    background-color: #e03c2e;
}

.pageImage {
    height: 100%;
    width: 100%;
}

.thumbnail-container{
    position: relative;
}

.chapter-overlay {
    position: absolute;
    bottom: 0px;
    right: 0px;
    color: #C40D00;
    background-color: white;
    border-radius: 0 0 0.37rem 0;
    padding: 0.2rem;
    font-weight: bold;
    font-size: 0.8rem;
}

.nextLink, .prevLink, .chapterLink {
    font-size: 3rem;
    color: #ffffff;
    margin: 1rem;
    padding: 0.2rem;
    border-style: solid;
    background-color: #C40D00;
    display: inline-block
}

.disabledLink {
    color: #444444;
    background-color: #580300;
    pointer-events: none;
    cursor: unset;
}

.title {
    color: #ffffff;
    font-weight: bold;
    font-size: 5rem;
}

.chapterPageText {
    color: #ffffff;
    float: right;
}

.navbar-inverse {
    background-color: #C40D00;
    border: 0px;
}

.invisible {
    opacity: 0;
}

/*to do: implement this one day...*/
img {
    /*  background: rgba(0,0,0,0);*/
}

.navbar-inverse .navbar-nav > li > a {
    color: #ffffff;
}

.page-navigation-cell {
    text-align: center;
    border-style: solid;
    border-width: 0.5rem;
    border-color: #e03c2e;
    margin: 0.5rem;
}

    .page-navigation-cell > a {
        color: #ffffff;
    }

.page-navigation-link {
    padding: 0.5rem;
    display: block;
}

.red-section {
    background-color: #c40d00;
    color: #ffffff;
    width: fit-content;
    padding: 1rem;
    margin-top: 1rem;
}

.white-text {
    color: #ffffff;
}


/* Fade In */
.fade-in {
    animation: fadeIn ease 2s;
    -webkit-animation: fadeIn ease 2s;
    -moz-animation: fadeIn ease 2s;
    -o-animation: fadeIn ease 2s;
    -ms-animation: fadeIn ease 2s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut ease 0.5s;
    -webkit-animation: fadeOut ease 0.5s;
    -moz-animation: fadeOut ease 0.5s;
    -o-animation: fadeOut ease 0.5s;
    -ms-animation: fadeOut ease 0.5s;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.hidden {
    visibility: hidden;
}

.container {
    padding-left: unset;
    padding-right: unset;
}

.social-media-link-image {
    height: 2rem;
    float: right
}

.dynamic-view-btn {
    background-color: #c40d00;
    color: #ffffff;
    margin: 0.5rem;
}

redskyfooter {
    margin: 0px;
}

.dynamic-canvas {
    touch-action: none
}